import React from 'react'
import styled from 'styled-components'
import { Badge, Pane } from 'evergreen-ui'
import { Typography } from '../components/primitives'

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 4em 2em;
  line-break: loose;
	ul {
		list-style: none;
	}

	ul li {
		display: flex !important;
	}

	ul li::before {
		content: "•";
		color: white;
		font-weight: bold;
		width: 1em;
	}

  a {
    color: ${({theme}) => theme.text };
  }
`


export const ChangeLog = () => {
  
	return (
		<Wrapper className="w-full md:w-2/3">
			<Typography className="mb-8" variant="h3" weight="black">Change Log</Typography>
			<Pane marginBottom="1rem">
				<Typography variant="h4" weight="bold">October 3, 2021</Typography>
				<Badge>v0.0.19</Badge>
				<ul className="mt-2">
					<li><Typography className="flex mb-2" variant="tiny" weight="light">Updated logic to be able to play single player team game.</Typography></li>
				</ul>
				<ul className="mt-2">
					<li><Typography className="flex mb-2" variant="tiny" weight="light">Removed links from TweetCard so users do not navigate away from game.</Typography></li>
				</ul>
				<ul className="mt-2">
					<li><Typography className="flex mb-2" variant="tiny" weight="light">Game now filters out tweets that contain a video.</Typography></li>
				</ul>
				<ul className="mt-2">
					<li><Typography className="flex mb-2" variant="tiny" weight="light">Fix bug that allowed users to inflate scores.</Typography></li>
				</ul>
			</Pane>
			<Pane marginBottom="1rem">
				<Typography variant="h4" weight="bold">August 8, 2021</Typography>
				<Badge>v0.0.16</Badge>
				<ul className="mt-2">
					<li><Typography className="flex mb-2" variant="tiny" weight="light">Updated game logging system</Typography></li>
				</ul>
				<ul className="mt-2">
					<li><Typography className="flex mb-2" variant="tiny" weight="light">Fixed New Game loop. Utter game gets stuck in loop after first category game is played</Typography></li>
				</ul>
				<ul className="mt-2">
					<li><Typography className="flex mb-2" variant="tiny" weight="light">Limited players and twitter accounts to 8.</Typography></li>
				</ul>
				<ul className="mt-2">
					<li><Typography className="flex mb-2" variant="tiny" weight="light">Fix player limit issue. Game modes should display 4 options for answers.</Typography></li>
				</ul>
			</Pane>
			{/* <Pane marginBottom="1rem">
				<Typography variant="h4" weight="bold">January 17, 2021</Typography>
				<Badge>v0.0.14</Badge>
				<ul className="mt-2">
					<li><Typography className="flex mb-2" variant="tiny" weight="light">{'Added "how to play" video!'}</Typography></li>
					<li><Typography className="flex mb-2" variant="tiny" weight="light">Added more categories!</Typography></li>
				</ul>
				<ul className="mt-2">
					<li><Typography className="flex mb-2" variant="tiny" weight="light">Addressed issue that incorrectly allowed tweets with external links and no pictures in category mode</Typography></li>
				</ul>
			</Pane> */}
			<Pane marginBottom="1rem">
				<Typography variant="h4" weight="bold">January 17, 2021</Typography>
				<Badge>v0.0.14</Badge>
				<ul className="mt-2">
					<li><Typography className="flex mb-2" variant="tiny" weight="light">{'Added "how to play" video!'}</Typography></li>
					<li><Typography className="flex mb-2" variant="tiny" weight="light">Added more categories!</Typography></li>
				</ul>
				<ul className="mt-2">
					<li><Typography className="flex mb-2" variant="tiny" weight="light">Addressed issue that incorrectly allowed tweets with external links and no pictures in category mode</Typography></li>
				</ul>
			</Pane>
			<Pane marginBottom="1rem">
				<Typography variant="h4" weight="bold">January 09, 2021</Typography>
				<Badge>v0.0.13</Badge>
				<ul className="mt-2">
					<li><Typography className="flex mb-2" variant="tiny" weight="light">Fixed issue that incorrectly filters tweet with images in category mode</Typography></li>
				</ul>
			</Pane>
			<Pane marginBottom="1rem">
				<Typography variant="h4" weight="bold">December 29, 2020</Typography>
				<Badge>v0.0.12</Badge>
				<ul className="mt-2">
					<li><Typography className="flex mb-2" variant="tiny" weight="light">Introduced a changelog. (That&apos;s the page you&apos;re on right now)</Typography></li>
					<li><Typography className="flex mb-2" variant="tiny" weight="light">Updated SEO metadata including title, description, and keywords</Typography></li>
					<li><Typography className="flex mb-2" variant="tiny" weight="light">Corrected issue where you could start a friends game with only three players as long as team two had two players.</Typography></li>
				</ul>
			</Pane>
		</Wrapper>
	)
}