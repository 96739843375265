import React from 'react'

import { Layout, Navbar } from '../Layout'
import { ChangeLog } from '../components/ChangeLog'

const ChangeLogPage = () => (
	<Layout
		title="Change Log"
		navbar={<Navbar position="absolute"/>}
		content={<ChangeLog/>}
		footer={null}
	/>
)

export default ChangeLogPage